// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
  },
};

class Blog_2017_06_09_Lower_Refrigerator extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AI" title="Lower Refrigerator" />
        <p>
          Canyons are the trash bins of Mother Nature. It’s where all the dead
          trees, dead animals, loose dirt, boulders, feces, etc. end up once
          they are no longer needed. Lower Refrigerator has the distinction of
          being the canyon that is not only Mother Nature’s trash bin but
          Angel’s Landing hikers’ trash bin as well. I have never seen so much
          trash in a canyon.
        </p>
        <p>
          We were originally scheduled to do Lower Echo but didn’t win the
          lottery for the permits. Plan B was Observation Point Canyon, but we
          needed something short and easy because this was supposed to be a rest
          day. We didn’t have a second vehicle to set up a shuttle so we opted
          to do Lower Refrigerator instead. Neither of us had done it yet and it
          fit the short and easy criteria so it won out.
        </p>
        <p>
          We started at the Grotto mid-morning and made quick work of the
          approach hike. We waited for a brief moment when there wasn’t anybody
          around and dropped into the canyon below the bridge. At every step
          there was a discarded or dropped article of clothing, camera,
          sunglasses, or toilet paper. More than any other canyon, I watched my
          step those first few hundred feet.
        </p>
        <Image
          caption="Second optional rappel. At this point we were still only 30 feet or so below the main trail."
          image={data._4}
        />
        <p>
          The top contains two optional rappels. We down climbed the first one
          but decided to rappel the second one after replacing the{' '}
          <GlossaryLink>accessory cord</GlossaryLink> used in place of{' '}
          <GlossaryLink>webbing</GlossaryLink>. If I ever do this canyon again
          I’ll attempt to down climb it.
        </p>
        <Image
          caption="View from the top of the final rappel sequence"
          image={data._2}
        />
        <p>
          Apparently at the bottom of the second rappel there may be a pool. It
          was just sand and I carelessly rappelled down into it without
          realizing it was a hole that I had to climb out of. I directed Randy
          out of the hole and we rigged the final drop sequence. 2 rappels and
          300 or so feet later we found ourselves at the bottom and done with
          the technical section. Very short indeed.
        </p>
        <Image
          caption="Grotto at the end of the technical section of the canyon."
          image={data._3}
        />
        <p>
          We agreed it was a quiet and scenic grotto considering we were
          surrounded by hordes of tourists hiking Angel’s Landing. One of us
          suggested we stay for a while so we ended up finding some comfortable{' '}
          <GlossaryLink>slickrock</GlossaryLink> and sleeping for about an hour.
          That little rest spot made the canyon worthwhile for me. I’d do it
          again if I know there will be enough time to rest at the bottom before
          hiking out.
        </p>
        <p>
          The ranger at the wilderness desk encouraged us to pack out as much
          garbage as we could. If I had known how much there would be, I would
          have brought a garbage sack and one of those grabber sticks to pick up
          the otherwise unreachable trash in the crevasses where my hands won’t
          reach. This time I could only fill my pockets and there were a few
          pieces of garbage I refused to touch.
        </p>
        <Image caption="Grotto looking up" image={data._4} />
        <p>
          Among the better stuff was a brand new hat, a generic brand go-pro,
          and a camera flash. Everything was smashed and unusable, but fun to
          look at nonetheless.
        </p>
        <p>
          A short hike through some boulders and small stream brought us back to
          the Angel’s Landing trail and ended the canyoneering for the day.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2017-06-09-lower-refrigerator')(
  Blog_2017_06_09_Lower_Refrigerator
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2017-06-09-lower-refrigerator/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2017-06-09-lower-refrigerator/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2017-06-09-lower-refrigerator/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2017-06-09-lower-refrigerator/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
